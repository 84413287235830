import { CustomTheme } from "@borrower-apps/theme";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CustomAppBar from "Components/CustomAppBar";
import DescriptionItem from "Components/DescriptionItem";
import { PhoneIcon } from "Components/Icons";
import PaymentGuide from "Components/PaymentGuide";
import SectionDivider from "Components/SectionDivider";
import { DATE_FORMAT } from "constants/format";
import dayjs from "dayjs";
import { ROUTES } from "helpers/routes";
import { formatVnd } from "helpers/utils";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContractRepayments } from "services";
import { useTranslation } from "react-i18next";

const MakePaymentPage = () => {
  const { t } = useTranslation();
  const theme: CustomTheme = useTheme();
  const navigate = useNavigate();
  const { contractNo } = useParams();
  const { payment } = useContractRepayments(contractNo);
  const [expanded, setExpanded] = useState(true);
  const [open, setOpen] = useState(false);
  const scrollTargetRef = React.useRef();
  const handleOnClose = () => {
    setOpen(false);
    setTimeout(() => {
      navigate(ROUTES.LIST_CONTRACTS, { replace: true });
    }, 100);
  };
  const AppBar = () => (
    <CustomAppBar
      scrollTarget={scrollTargetRef?.current}
      goBack={handleOnClose}
      title={
        <Box textAlign="center">
          {t("payment.title")}
          <Box component="span" color={theme.colors.pink[400]}>
            <Typography component="span" color="inherit" variant="h2">
              {" #"}
              {payment?.contractNo}
            </Typography>
          </Box>
        </Box>
      }
      extra={
        <Stack>
          <Box color={theme.palette.primary.main}>
            <IconButton color="inherit" href="tel:1900234534">
              <PhoneIcon />
            </IconButton>
          </Box>
        </Stack>
      }
      sx={{
        width: { xs: "100%", sm: "60%" },
        paddingBottom: 4,
        maxWidth: 500,
      }}
    />
  );

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  return (
    <Drawer
      open={open}
      onClose={handleOnClose}
      anchor="right"
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "60%" },
          paddingBottom: 4,
          maxWidth: 500,
        },
        ref: scrollTargetRef,
      }}
    >
      <AppBar />
      <Stack spacing={4} marginTop={10}>
        <Container>
          <Card variant="outlined">
            <CardContent>
              <Accordion
                expanded={expanded}
                sx={{ boxShadow: "none", paddingY: 0 }}
              >
                <AccordionSummary
                  sx={{
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    justifyItems: "flex-start",
                  }}
                  expandIcon={false}
                >
                  <Stack
                    spacing={1}
                    alignItems="center"
                    sx={{ width: "100%", mr: 1 }}
                  >
                    <DescriptionItem
                      label={t("payment.totalAmount")}
                      value={
                        payment?.totalAmount
                          ? formatVnd(payment?.totalAmount)
                          : undefined
                      }
                      justifyContent="space-between"
                    />
                    <DescriptionItem
                      label={t("payment.paymentPeriod")}
                      value={
                        payment?.turn
                          ? `${payment.turn}/${payment.tenors}`
                          : undefined
                      }
                      justifyContent="space-between"
                    />
                    <DescriptionItem
                      label={t("payment.dueDate")}
                      value={
                        payment?.dueDate
                          ? dayjs(payment?.dueDate).format(DATE_FORMAT)
                          : undefined
                      }
                      justifyContent="space-between"
                    />
                  </Stack>
                </AccordionSummary>
                <Stack spacing={3}></Stack>
                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                  <Stack spacing={3}>
                    <Divider />
                    <Box my={2}>
                      <Stack
                        spacing={1}
                        alignItems="center"
                        sx={{ width: "100%", mr: 1 }}
                      >
                        <DescriptionItem
                          label={
                            <Typography color="inherit">
                              {t("payment.principalPayment")}
                              <Box
                                component="span"
                                color={theme.colors.pink[100]}
                              >
                                <Typography component="span" color="inherit">
                                  {" "}
                                  (a)
                                </Typography>
                              </Box>
                            </Typography>
                          }
                          value={
                            payment?.curPrincipal
                              ? formatVnd(payment?.curPrincipal)
                              : undefined
                          }
                          justifyContent="space-between"
                        />
                        <DescriptionItem
                          label={t("payment.daysLate")}
                          value={payment?.dpd}
                          justifyContent="space-between"
                        />
                        <DescriptionItem
                          label={
                            <Typography color="inherit">
                              {t("payment.previousPrincipal")}
                              <Box
                                component="span"
                                color={theme.colors.pink[100]}
                              >
                                <Typography component="span" color="inherit">
                                  {" "}
                                  (b)
                                </Typography>
                              </Box>
                            </Typography>
                          }
                          value={
                            payment?.prevPrincipal
                              ? formatVnd(payment?.prevPrincipal)
                              : undefined
                          }
                          justifyContent="space-between"
                        />
                        <DescriptionItem
                          label={
                            <Typography color="inherit">
                              {t("payment.latePaymentFees")}
                              <Box
                                component="span"
                                color={theme.colors.pink[100]}
                              >
                                <Typography component="span" color="inherit">
                                  {" "}
                                  (c)
                                </Typography>
                              </Box>
                            </Typography>
                          }
                          value={
                            payment?.dueFees
                              ? formatVnd(payment?.dueFees)
                              : undefined
                          }
                          justifyContent="space-between"
                        />
                        <DescriptionItem
                          label={
                            <Typography color="inherit">
                              {t("payment.totalToBePaid")}
                              <Box
                                component="span"
                                color={theme.colors.pink[100]}
                              >
                                <Typography component="span" color="inherit">
                                  {" "}
                                  ((a) + (b) + (c))
                                </Typography>
                              </Box>
                            </Typography>
                          }
                          value={
                            payment?.totalAmountToBePaid
                              ? formatVnd(payment?.totalAmountToBePaid)
                              : undefined
                          }
                          justifyContent="space-between"
                        />
                      </Stack>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Stack sx={{ mt: 2 }} alignItems="center">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setExpanded(!expanded)}
                  sx={{ width: "100%" }}
                >
                  {expanded
                    ? t("payment.toggleButton.collapse")
                    : t("payment.toggleButton.expand")}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Container>
        <SectionDivider />
        <Container>
          <PaymentGuide
            amount={payment?.totalAmountToBePaid ?? 0}
            transferContent={payment?.contractNo ?? ""}
          />
        </Container>
      </Stack>
    </Drawer>
  );
};

export default MakePaymentPage;
