
export enum CONTRACT_STATUS {
  NEW = 0,
  VERIFYING = 1,
  APPRAISING = 2,
  ACCEPTED = 3,
  FULFILLED = 4,
  DISBURSED = 5,
  COLLECTING = 6,
  COMPLETED = 7,
  OVERDUE = 8,
  CANCELED = 9,
  REJECTED = 10,
  FULFILL_PENDING = 11,
  DEFAULT = 12,
}

export const REQUEST_STATUS_NAME = {
  [CONTRACT_STATUS.ACCEPTED]: "Đã cam kết",
  [CONTRACT_STATUS.FULFILLED]: "Đợi giải ngân",
  [CONTRACT_STATUS.DISBURSED]: "Đã giải ngân",
  [CONTRACT_STATUS.COLLECTING]: "Đang thanh toán",
  [CONTRACT_STATUS.COMPLETED]: "Đã tất toán",
  [CONTRACT_STATUS.CANCELED]: "Đã huỷ",
  [CONTRACT_STATUS.OVERDUE]: "Trả chậm",
  [CONTRACT_STATUS.DEFAULT]: "Nợ xấu",
}
