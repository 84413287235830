import { CustomTheme } from "@borrower-apps/theme";
import { useTheme } from "@emotion/react";
import {
  AppBar,
  AppBarProps,
  Box,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { ArrowLeftIcon } from "Components/Icons";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ElevationScroll from "./ElevationScroll";

interface CustomAppBarProps extends Omit<AppBarProps, "title"> {
  title?: string | ReactNode;
  goBack?: (e?: any) => void;
  extra?: ReactNode | ReactNode[];
  scrollTarget?: Node | undefined;
}
const CustomAppBar: React.FC<CustomAppBarProps> = ({
  position,
  children,
  title,
  extra,
  goBack,
  scrollTarget,
  style,
  ...props
}) => {
  const theme: CustomTheme = useTheme();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else if (navigate.length) {
      navigate(-1);
    }
  };

  return (
    <ElevationScroll scrollTarget={scrollTarget}>
      <AppBar
        {...props}
        position={position}
        style={{ background: theme.palette.common.white, ...style }}
      >
        <Container>
          <Toolbar disableGutters>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr auto 1fr",
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  textAlign: "left",
                  color: theme.palette.text.primary,
                  fontSize: 24,
                }}
              >
                <IconButton
                  color="inherit"
                  onClick={handleGoBack}
                  style={{ marginLeft: -12 }}
                >
                  <ArrowLeftIcon />
                </IconButton>
              </Box>

              <Typography variant="h2" color="textPrimary">
                {title}
              </Typography>

              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                {extra}
              </Stack>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default CustomAppBar;
