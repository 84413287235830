const contract = {
  list: {
    title: "List of Installment Contracts"
  },
  item: {
    label: {
      id: "Contract ID",
      disbursedDate: "Disbursed Date",
      status: "Contract Status",
      totalAmount: "Total Amount",
      paid: "Paid",
      dueDate: "Due Date",
      turn: "Turn",
      detailAndPaymentButton: "Details and Payment",
    }
  },
  statusText: {
    6: "In repayment",
    5: "In repayment",
    7: "Fully paid",
    8: "Late payment",
    12: "Late payment",
    other: "N/A",
  }
}

export default contract;