import { CustomTheme } from "@borrower-apps/theme";
import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { CONTRACT_STATUS } from "constants/contract";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "constants/format";
import dayjs from "dayjs";
import { ROUTES } from "helpers/routes";
import { formatVnd } from "helpers/utils";
import { Contract } from "model";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import urlcat from "urlcat";

const ContractCard: React.FC<{ contract: Contract }> = ({ contract }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme: CustomTheme = useTheme();
  const statusColor = () => {
    switch (contract.status) {
      case CONTRACT_STATUS.DISBURSED:
      case CONTRACT_STATUS.COLLECTING:
        return theme.palette.info.main;
      case CONTRACT_STATUS.COMPLETED:
        return theme.palette.success.main;
      case CONTRACT_STATUS.OVERDUE:
      case CONTRACT_STATUS.DEFAULT:
        return theme.palette.error.main;
      default:
        return theme.palette.warning.main;
    }
  };

  const StatusBadge = () => {
    return (
      <Box
        sx={{
          px: 2,
          py: 0.5,
          bgcolor: statusColor(),
          borderRadius: 999,
          color: theme.palette.common.white,
        }}
      >
        <Typography color="inherit">
          {t(`contract.statusText.${contract.status}`)}
        </Typography>
      </Box>
    );
  };

  return (
    <Card
      variant="outlined"
      sx={{ borderColor: statusColor(), height: "100%" }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption">
                #{contract.id.toUpperCase()}
              </Typography>
              <Typography variant="caption">
                {dayjs(contract.disbursedDate).format(DATE_TIME_FORMAT)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption">
                {t("contract.item.label.status")}:{" "}
              </Typography>
              <StatusBadge />
            </Stack>
            <Typography variant="caption">
              {t("contract.item.label.totalAmount")}:{" "}
              <Typography component={"span"} variant="body1">
                {formatVnd(contract.totalAmount)}
              </Typography>
            </Typography>
            <Typography variant="caption">
              {t("contract.item.label.paid")}:{" "}
              <Typography component={"span"} variant="body1">
                {contract.turn}/{contract.tenors}{" "}
                {t("common.turn", {
                  count: contract.tenors,
                }).toLocaleLowerCase()}
              </Typography>
            </Typography>
            <Typography variant="caption">
              {t("contract.item.label.dueDate")}:{" "}
              <Typography component={"span"} variant="body1">
                {dayjs(contract.dueDate).format(DATE_FORMAT)}
              </Typography>
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Avatar sizes="medium" />
            <Stack>
              <Typography variant="h2" component="div">
                {contract.kidName}
              </Typography>
              <Typography variant="h2" component="div">
                {contract.schoolName}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      {!(contract.status === CONTRACT_STATUS.COMPLETED) && (
        <CardActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              const url = urlcat("", ROUTES.MAKE_PAYMENT, {
                contractNo: contract.contractNo,
              });

              navigate(url);
            }}
            style={{ width: "100%", borderRadius: 9999 }}
          >
            {t("contract.item.label.detailAndPaymentButton")}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ContractCard;
