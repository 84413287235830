import keycloak from "../../keycloak";

/**
 * Fetches data from the specified URL.
 *
 * @param endpoint The URL to fetch data from.
 * @param options Additional options for the fetch request.
 * @throws {Error} If an error occurs while fetching the data.
 * @returns {Promise<any>} A promise that resolves to the fetched data.
 */
export const fetchData = async (endpoint: string, options?: any): Promise<any> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "X-REALM": 'borrowers',
    Authorization: `Bearer ${keycloak.token}`,
    ...(options?.headers ?? {}),
  };

  const fetchOptions = {
    ...(options ?? {}),
    method: options?.method ?? 'GET',
    headers
  };

  const response = await fetch(`${apiUrl}${endpoint}`, fetchOptions);

  if (!response.ok) {
    if (response.status === 401) {
      keycloak.logout();
    }
    const error: Error & { info?: any, status?: number } = new Error('Failed to fetch data');
    error.info = await response.json();
    error.status = response.status;
    throw error;
  }

  return response.json();
};
