import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ROUTES } from "helpers/routes";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Anchor = "top" | "left" | "bottom" | "right";
interface MenuDrawerProps extends DrawerProps {
  anchor?: Anchor;
}

const MenuMobile: React.FC<MenuDrawerProps> = ({ anchor = "left" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const items = [
    {
      name: t("menu.main.contracts"),
      route: ROUTES.LIST_CONTRACTS,
    },
  ];

  const toggleMenu =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      const isKeyEvent = event.type === "keydown";
      const isTabKey = (event as React.KeyboardEvent).key === "Tab";
      const isShiftKey = (event as React.KeyboardEvent).key === "Shift";

      if (isKeyEvent && (isTabKey || isShiftKey)) return;

      setOpen(isOpen);
    };

  const generateListItems = (items: { name: string; route: string }[]) =>
    items.map(({ name: text, route }, index) => (
      <ListItem key={text} disablePadding>
        <ListItemButton onClick={() => navigate(route)}>
          <ListItemIcon>
            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    ));

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleMenu(false)}
      onKeyDown={toggleMenu(false)}
    >
      <List>{generateListItems(items)}</List>
    </Box>
  );

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleMenu(true)}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Drawer open={open} anchor={anchor} onClose={toggleMenu(false)}>
        {list(anchor)}
      </Drawer>
    </>
  );
};

export default MenuMobile;
