import { ROUTES } from "helpers/routes";
import { Link } from "react-router-dom";
import Logo from "../../logo.png";

const AppBarLogo = () => {
  return (
    <Link to={ROUTES.LIST_CONTRACTS}>
      <img width={32} src={Logo} />
    </Link>
  );
};

export default AppBarLogo;
