import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "configs/routes";
import ResponsiveAppBar from "Components/ResponsiveAppBar";

function App() {
  useEffect(() => {
    window.addEventListener("resize", () => {
      const rootElement = document.documentElement;
      rootElement.style.setProperty(
        "--screen-height",
        `${window.innerHeight}px`
      );
    });
  }, []);

  return (
    <BrowserRouter>
      <ResponsiveAppBar />
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
