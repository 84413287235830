const payment = {
  title: 'Regular Payment',
  totalAmount: 'Total Amount',
  paymentPeriod: 'Payment Period',
  dueDate: 'Due Date',
  principalPayment: 'Principal payment of period (a)',
  daysLate: 'Days Late',
  previousPrincipal: 'Remaining principal from the previous period',
  latePaymentFees: 'Late Payment Fees',
  totalToBePaid: 'Total to be paid',
  toggleButton: {
    expand: 'Expand',
    collapse: 'Collapse',
  },
  paymentGuide: {
    title: "Payment Instructions:",
    step1: { title: "Step 1: ", description: "Open your banking app, select 'Transfer 24/7'" },
    step2: { title: "Step 2: ", description: "Enter the EXACT content to be recorded quickly (click 'Copy' to copy)" },
    bank: "Bank",
    accountNumber: "Account Number",
    amount: "Amount",
    narrative: "Transfer Content",
    copied: "Copied",
    copy: "Copy"
  }
}

export default payment;