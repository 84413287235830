import { useScrollTrigger } from "@mui/material";

import { CustomTheme } from "@borrower-apps/theme";
import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      borderRadius: 0,
      transition: "padding .3s, background .2s",
      zIndex: 100,
      paddingBlock: theme.spacing(),
      "&.elevated": {
        paddingBlock: theme.spacing(0.5),
        boxShadow: theme.shadows[3],
      },
    },
  })
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
  disabledElevationScroll?: boolean;
  scrollTarget?: Node;
}

const ElevationScroll = (props: Props) => {
  const classes = useStyles();
  const { children, window: getWindow, disabledElevationScroll = false, scrollTarget = undefined } = props;
  const target = scrollTarget || (getWindow ? getWindow() : undefined);
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0, target }) && !disabledElevationScroll;
  
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    className: trigger ? `${classes.root} elevated` : classes.root,
  });
};

export default ElevationScroll;
