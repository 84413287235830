import { Box, Button } from "@mui/material";
import { ROUTES } from "helpers/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DesktopMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      <Button
        key={"contracts"}
        sx={{ color: "white", display: "block" }}
        onClick={() => navigate(ROUTES.LIST_CONTRACTS)}
      >
        {t("menu.main.contracts")}
      </Button>
    </Box>
  );
};

export default DesktopMenu;
