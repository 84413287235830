import useSWR from "swr";
import { contractRepaymentsFetcher, contractsFetcher } from "../libs/api-contract";
import { API } from "../utils/api";
import urlcat from "urlcat";
import { Contract, Repayment } from "../../model";

type UseContractReturnType = {
  isLoading: boolean;
  contracts: Contract[] | undefined;
  mutate: () => void;
};

type UseContractRepaymentsReturnType = {
  isLoading: boolean;
  payment: Repayment | undefined;
  mutate: () => void;
};


export function useContracts(): UseContractReturnType {
  const { data, mutate, error, isLoading } = useSWR<IResponseType<ContractResponseType[]>, Error>(
    API.REPAYMENT_OF_CONTRACT_GET_ALL,
    contractsFetcher
  );

  const contracts = data?.data?.map((datum: ContractResponseType) => new Contract(datum));

  return {
    isLoading,
    contracts,
    mutate
  };
};

export function useContractRepayments(contractNo: string | undefined): UseContractRepaymentsReturnType {
  const key = urlcat('', API.REPAYMENT_OF_CONTRACT_GET_ALL, { contractNo });
  const fetcher = () => contractRepaymentsFetcher(contractNo);
  const { data, mutate, error, isLoading } = useSWR<IResponseType<RepaymentResponseType>, Error>(key, fetcher);
  const repayment = data?.data;

  return {
    isLoading,
    payment: repayment ? new Repayment(repayment) : undefined,
    mutate
  };
};



