import i18n from "configs/i18n"; // Import the i18n configuration

export class Contract {
  id: string = '';
  status: number = 0;
  dealSize: number = 0;
  dealInterest: number = 0;
  dpd: number = 0;
  maxDpd: number = 0;
  totalPaid: number = 0;
  paidPrincipal: number = 0;
  paidFees: number = 0;
  prevPrincipal: number = 0;
  prevFees: number = 0;
  curFees: number = 0;
  curPrincipal: number = 0;
  dueFees: number = 0;
  duePrincipal: number = 0;
  turn: number = 0;
  tenors: number = 0;
  contractNo: string = '';
  disbursedDate: string = '';
  dueDate: string = '';
  borrowerName: string = '';
  schoolName: string = '';
  schoolAlias: string = '';
  kidName: string = '';

  constructor(initialState: ContractResponseType) {
    if (!initialState) throw new Error();

    this.updateFromJson(initialState);
  }

  updateFromJson(contract: ContractResponseType): void {
    this.id = contract.id;
    this.status = Number(contract.status);
    this.dealSize = Number(contract.dealSize);
    this.dealInterest = Number(contract.dealInterest);
    this.dpd = Number(contract.dpd);
    this.maxDpd = Number(contract.maxDpd);
    this.totalPaid = Number(contract.totalPaid);
    this.paidPrincipal = Number(contract.paidPrincipal);
    this.paidFees = Number(contract.paidFees);
    this.prevPrincipal = Number(contract.prevPrincipal);
    this.prevFees = Number(contract.prevFees);
    this.curFees = Number(contract.curFees);
    this.curPrincipal = Number(contract.curPrincipal);
    this.dueFees = Number(contract.dueFees);
    this.duePrincipal = Number(contract.duePrincipal);
    this.turn = Number(contract.turn);
    this.tenors = Number(contract.tenors);
    this.contractNo = contract.contractNo;
    this.disbursedDate = contract.disbursedDate;
    this.dueDate = contract.dueDate;
    this.borrowerName = contract.borrowerName;
    this.schoolName = contract.schoolName;
    this.schoolAlias = contract.schoolAlias;
    this.kidName = contract.kidName;
  }

  get totalAmount() {
    const principalDue = this.duePrincipal || 0;
    const feesDue = this.dueFees || 0;
    return principalDue + feesDue;
  }
}