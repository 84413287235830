import { Box, Container, useTheme } from "@mui/material";
import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ElevationScroll from "Components/CustomAppBar/ElevationScroll";
import { ROUTES } from "helpers/routes";
import * as React from "react";
import AppBarLogo from "../AppLogo";
import DesktopMenu from "./DesktopMenu";
import MenuMobile from "./MobileMenu";
import UserMenu from "./UserMenu";
import { CustomTheme } from "@borrower-apps/theme";

interface ResponsiveAppBarProps extends AppBarProps {
  scrollTarget?: Node;
}

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = ({
  scrollTarget = undefined,
  position = "fixed",
  ...props
}) => {
  const theme: CustomTheme = useTheme();

  return (
    <>
      <ElevationScroll scrollTarget={scrollTarget} {...props}>
        <AppBar position={position}>
          <Container>
            <Toolbar disableGutters>
              <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
                <AppBarLogo />
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <MenuMobile />
              </Box>

              <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
                <AppBarLogo />
              </Box>
              <Typography
                noWrap
                variant="h5"
                component="a"
                href={ROUTES.LIST_CONTRACTS}
                sx={{
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Rootopia
              </Typography>

              <DesktopMenu />

              <Box sx={{ flexGrow: 0 }}>
                <UserMenu />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      {position === "fixed" && (
        <Box
          height={theme.spacing(9)}
          marginBottom={theme.spacing(2)}
          style={{ pointerEvents: "none" }}
        />
      )}
    </>
  );
};
export default ResponsiveAppBar;
