import { defaultTheme } from "@borrower-apps/theme";
import { CssBaseline, Stack } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import Loading from "Components/Loading/Loading";
import keycloak from "./keycloak";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import ErrorBoundary from "Components/ErrorBoundary/ErrorBoundary";

import i18n from "configs/i18n"; // Import the i18n configuration

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const eventLogger = (event: unknown, error: unknown) => {
  if (process.env.NODE_ENV === "development") {
    console.log("onKeycloakEvent", event, error);
  }
};

const tokenLogger = (tokens: unknown) => {
  if (process.env.NODE_ENV === "development") {
    console.log("onKeycloakTokens", tokens);
  }
};

root.render(
  <ReactErrorBoundary fallback={<ErrorBoundary />}>
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": { "--screen-height": `${window.innerHeight}px` },
          "#root": {
            position: "relative",
            minHeight: "var(--screen-height)",
          },
        }}
      />
      <Suspense
        fallback={
          <Stack height="var(--screen-height)" justifyContent="center">
            {/* <Loading /> */}
            {i18n.t("appLoading")}
          </Stack>
        }
      >
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
          LoadingComponent={
            <Stack height="var(--screen-height)" justifyContent="center">
              <Loading />
            </Stack>
          }
          initOptions={{
            flow: "implicit",
            // onLoad: "login-required",
            // onLoad: "check-sso",
          }}
        >
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </ReactKeycloakProvider>
      </Suspense>
    </ThemeProvider>
  </ReactErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
