"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@mui/material/styles");
const system_1 = require("@mui/system");
const colors_1 = require("./colors");
const breakpoints = (0, system_1.createBreakpoints)({});
const theme = (0, styles_1.createTheme)({
    maxContent: 430,
    mixins: {
        toolbar: {
            minHeight: 56,
        },
    },
    palette: {
        mode: "light",
        background: {
            default: colors_1.common.white,
        },
        divider: colors_1.divider,
        common: colors_1.common,
        primary: colors_1.primary,
        secondary: colors_1.secondary,
        success: colors_1.success,
        error: colors_1.error,
        warning: colors_1.warning,
        info: colors_1.info,
        text: colors_1.text,
        grey: colors_1.grey,
    },
    colors: {
        purple: colors_1.purple,
        green: colors_1.green,
        orange: colors_1.orange,
        red: colors_1.red,
        blue: colors_1.blue,
        coral: colors_1.coral,
        cyan: colors_1.cyan,
        pink: colors_1.pink,
    },
    typography: (palette) => ({
        htmlFontSize: 16,
        fontFamily: `"Public Sans", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
        },
        h2: {
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        h3: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
        },
        h4: {
            fontWeight: 500,
            fontSize: "0.75rem",
            lineHeight: "1.25rem",
        },
        subtitle1: {
            fontSize: 12,
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: palette.text.secondary,
        },
        caption: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: palette.text.secondary,
        },
        body1: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: palette.text.primary,
        },
        button: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            textTransform: "none",
        },
    }),
    shape: {
        borderRadius: 8,
    },
    shadows: [
        "none",
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 8px 16px -6px #18274B14",
        "0px 12px 16px -4px rgba(47, 63, 113, 0.08), 0px 4px 6px -2px rgba(47, 63, 113, 0.04)",
        "0px 6px 12px -6px rgba(24, 39, 75, 0.24), 0px 8px 24px -4px rgba(24, 39, 75, 0.16)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    ],
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                },
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    "&.MuiButton-contained": {
                        boxShadow: "none",
                    },
                },
                sizeLarge: {
                    fontSize: 14,
                    height: 50,
                },
                sizeMedium: {
                    fontSize: 14,
                    height: 40,
                },
                sizeSmall: {
                    fontSize: 14,
                    height: 32,
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {},
                outlined: {
                    borderWidth: 1.5,
                },
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    "&:last-child": {
                        padding: theme.spacing(2),
                    },
                }),
            },
        },
    },
});
exports.default = theme;
