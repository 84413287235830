import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";

/**
 * Renders a loading spinner.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.size=32] - The size of the spinner in pixels.
 * @returns {JSX.Element} - The loading component.
 */
const Loading: React.FC<{ size?: number }> = ({ size = 32 }) => {
  const theme = useTheme();

  return (
    <Box
      color={theme.palette.primary.main}
      textAlign="center"
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="inherit" size={`${size}px`} />
    </Box>
  );
};

export default Loading;
