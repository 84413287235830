import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from 'locales/en';
import viTranslation from 'locales/vi';

const resources = {
  en: {
    translation: enTranslation,
  },
  vi: {
    translation: viTranslation,
  },
};

i18n
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    resources,
    lng: 'vi', // Default language
    fallbackLng: 'vi', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
