import React, { ReactElement, SVGProps } from "react";
import BaseIcon from "./BaseIcon";

export default function CopyIcon(props: SVGProps<any>): ReactElement {
  return (
    <BaseIcon {...props}>
      <path
        d="M19.68 8.64h-8.64a1.92 1.92 0 0 0 -1.92 1.92v8.64a1.92 1.92 0 0 0 1.92 1.92h8.64a1.92 1.92 0 0 0 1.92 -1.92v-8.64a1.92 1.92 0 0 0 -1.92 -1.92Z"
        stroke="currentColor"
        strokeWidth="1.92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.28 14.4h-0.96a1.92 1.92 0 0 1 -1.92 -1.92V3.84a1.92 1.92 0 0 1 1.92 -1.92h8.64a1.92 1.92 0 0 1 1.92 1.92v0.96"
        stroke="currentColor"
        strokeWidth="1.92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
}
