import React, { ReactElement, SVGProps } from "react";
import BaseIcon from "./BaseIcon";

export default function PhoneIcon(props: SVGProps<any>): ReactElement {
  return (
    <BaseIcon {...props}>
      <path
        d="M8.671 11.7a7.837 7.837 0 0 0 3.657 3.647.75.75 0 0 0 .741-.055l2.343-1.566a.741.741 0 0 1 .712-.066l4.387 1.885a.74.74 0 0 1 .45.777A4.5 4.5 0 0 1 16.5 20.25 12.75 12.75 0 0 1 3.75 7.5a4.5 4.5 0 0 1 3.929-4.463.741.741 0 0 1 .778.45l1.884 4.396a.75.75 0 0 1-.057.704L8.72 10.97a.75.75 0 0 0-.048.73v0Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </BaseIcon>
  );
}
