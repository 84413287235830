import BaseIcon from "Components/Icons/BaseIcon";
import React, { ReactElement, SVGProps } from "react";

export default function ArrowLeftIcon(props: SVGProps<any>): ReactElement {
  return (
    <BaseIcon {...props}>
      <path
        d="M20.25 12H3.75m6.75-6.75L3.75 12l6.75 6.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
}
