const contract = {
  list: {
    title: "Danh sách các hợp đồng trả góp"
  },
  item: {
    label: {
      id: "Mã hợp đồng",
      disbursedDate: "Ngày giải ngân",
      status: "Trạng thái hợp đồng",
      totalAmount: "Tổng tiền",
      paid: "Đã thanh toán",
      dueDate: "Ngày đến hạn thanh toán",
      turn: "Kỳ",
      detailAndPaymentButton: "Chi tiết và thanh toán"
    }
  },
  statusText: {
    5: "Đang thanh toán",
    6: "Đang thanh toán",
    7: "Đã tất toán",
    8: "Trả chậm",
    12: "Trả chậm",
    other: "N/A",
  }
}

export default contract;