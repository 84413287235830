import menu from "locales/en/menu";
import common from "locales/en/common";
import contract from "locales/en/contract";
import payment from "locales/en/payment";

const en = {
  menu,
  common,
  contract,
  payment
}

export default en;
