import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import DescriptionItem from "Components/DescriptionItem";
import { CopyIcon } from "Components/Icons";
import { formatVnd } from "helpers/utils";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

interface PaymentGuideProps {
  amount: number;
  transferContent: string;
}

const PaymentGuide: React.FC<PaymentGuideProps> = ({
  amount,
  transferContent,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState({
    bank: false,
    accountNumber: false,
    amount: false,
    narrative: false,
  });

  const handleCopied = (type: keyof typeof copied) => {
    setCopied({
      bank: false,
      accountNumber: false,
      amount: false,
      narrative: false,
      [type]: true,
    });
  };

  const renderCopyButton = (text: string, type: keyof typeof copied) => {
    const handleCopy = () => handleCopied(type);
    const buttonText = copied[type]
      ? t("payment.paymentGuide.copied")
      : t("payment.paymentGuide.copy");

    return (
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <Button startIcon={<CopyIcon />}>{buttonText}</Button>
      </CopyToClipboard>
    );
  };

  const renderCard = (
    label: string,
    value: string,
    type: keyof typeof copied
  ) => (
    <Card variant="outlined">
      <CardContent sx={{ p: 2, paddingBottom: 2 }}>
        <Stack>
          <Typography variant="caption">{label}</Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">{value}</Typography>
            {renderCopyButton(value, type)}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h1">{t("payment.paymentGuide.title")}</Typography>
      <Stack spacing={1}>
        <DescriptionItem
          label={t("payment.paymentGuide.step1.title")}
          value={t("payment.paymentGuide.step1.description")}
        />
        <DescriptionItem
          label={t("payment.paymentGuide.step2.title")}
          value={t("payment.paymentGuide.step2.description")}
        />
      </Stack>
      <Stack>
        <Stack spacing={1}>
          {renderCard(
            t("payment.paymentGuide.bank"),
            "OCB: Ngân hàng TMCP Phương Đông",
            "bank"
          )}
          {renderCard(
            t("payment.paymentGuide.accountNumber"),
            "0017101336668888",
            "accountNumber"
          )}
          {renderCard(
            t("payment.paymentGuide.amount"),
            formatVnd(amount),
            "amount"
          )}
          {renderCard(
            t("payment.paymentGuide.narrative"),
            transferContent,
            "narrative"
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PaymentGuide;
