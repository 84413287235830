import MakePaymentPage from "pages/makePayment";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../Components/PrivateRoute/PrivateRoute";
import { ROUTES } from "../helpers/routes";
import ContractListPage from "../pages/contracts";

export const AppRoutes = () => (
  <Routes>
    <Route
      path={ROUTES.LIST_CONTRACTS}
      element={
        <PrivateRoute>
          <ContractListPage />
        </PrivateRoute>
      }
    >
      <Route
        path={ROUTES.MAKE_PAYMENT}
        element={
          <PrivateRoute>
            <MakePaymentPage />
          </PrivateRoute>
        }
      />
    </Route>
  </Routes>
);
