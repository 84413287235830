import urlcat from "urlcat"
import { API } from "../utils/api";
import { fetchData } from "../utils/fetcher";

const contractsFetcher = async (params?: any): Promise<IResponseType<ContractResponseType[]>> => {
  const apiUrl = urlcat('', API.CONTRACT_GET_ALL);
  return await fetchData(apiUrl);
}
const contractRepaymentsFetcher = async (contractNo: string | undefined): Promise<any> => {
  const apiUrl = urlcat('', API.REPAYMENT_OF_CONTRACT_GET_ALL, { contractNo });
  return await fetchData(apiUrl);
}

export {
  contractsFetcher,
  contractRepaymentsFetcher
}