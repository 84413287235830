export class Repayment {
  id: string = '';
  status: number = 0;
  dealSize: number = 0;
  dealInterest: number = 0;
  dpd: number = 0;
  maxDpd: number = 0;
  totalPaid: number = 0;
  paidPrincipal: number = 0;
  paidFees: number = 0;
  prevPrincipal: number = 0;
  prevFees: number = 0;
  curFees: number = 0;
  curPrincipal: number = 0;
  dueFees: number = 0;
  duePrincipal: number = 0;
  turn: number = 0;
  tenors: number = 0;
  contractNo: string = '';
  disbursedDate: string = '';
  dueDate: string = '';
  borrowerName: string = '';
  schoolName: string = '';
  schoolAlias: string = '';
  kidName: string = '';

  constructor(initialState: RepaymentResponseType) {
    if (!initialState) throw new Error('Wrong data!');

    this.updateFromJson(initialState);
  }

  updateFromJson(repayment: RepaymentResponseType): void {
    this.id = repayment.id;
    this.status = Number(repayment.status);
    this.dealSize = Number(repayment.dealSize);
    this.dealInterest = Number(repayment.dealInterest);
    this.dpd = Number(repayment.dpd);
    this.maxDpd = Number(repayment.maxDpd);
    this.totalPaid = Number(repayment.totalPaid);
    this.paidPrincipal = Number(repayment.paidPrincipal);
    this.paidFees = Number(repayment.paidFees);
    this.prevPrincipal = Number(repayment.prevPrincipal);
    this.prevFees = Number(repayment.prevFees);
    this.curFees = Number(repayment.curFees);
    this.curPrincipal = Number(repayment.curPrincipal);
    this.dueFees = Number(repayment.dueFees);
    this.duePrincipal = Number(repayment.duePrincipal);
    this.turn = Number(repayment.turn);
    this.tenors = Number(repayment.tenors);
    this.contractNo = repayment.contractNo;
    this.disbursedDate = repayment.disbursedDate;
    this.dueDate = repayment.dueDate;
    this.borrowerName = repayment.borrowerName;
    this.schoolName = repayment.schoolName;
    this.schoolAlias = repayment.schoolAlias;
    this.kidName = repayment.kidName;
  }

  get totalAmount() {
    const principalDue = this.duePrincipal || 0;
    const feesDue = this.dueFees || 0;
    return principalDue + feesDue;
  }
  
  get totalAmountToBePaid() {
    const fees = this.dueFees || 0;
    const principal = this.prevPrincipal || 0;
    return this.totalAmount + fees + principal;
  }
}