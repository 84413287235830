import type { ReactNode, SVGProps } from "react";

export interface SvgProps extends SVGProps<any> {
  children: ReactNode;
}

const BaseIcon = (props: SvgProps) => {
  const { children, viewBox, width, height, fill, ...rest } = props;

  return (
    <svg
      {...rest}
      width={width || "1em"}
      height={height || "1em"}
      viewBox={viewBox ?? "0 0 24 24"}
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

export default BaseIcon;
