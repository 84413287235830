import { Box, Container, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ContractCard from "Components/ContractCard";
import { Contract } from "model";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useContracts } from "services";

const ContractListPage = () => {
  const { t } = useTranslation();
  const { contracts = [], isLoading } = useContracts();

  if (isLoading) return <></>;

  return (
    <Box marginBottom={4}>
      <Container>
        <Stack spacing={2}>
          <Typography variant="h1">{t("contract.list.title")}</Typography>
          <Box component="span">
            <Grid
              container
              rowSpacing={{ xs: 2, md: 2 }}
              columnSpacing={{ xs: 2, md: 2 }}
            >
              {contracts.map((contract: Contract) => {
                return (
                  <Grid key={contract.id} item xs={12} sm={6} lg={4}>
                    <ContractCard key={contract.id} contract={contract} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </Container>
      <Outlet />
    </Box>
  );
};

export default ContractListPage;
