import { CustomTheme } from "@borrower-apps/theme";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

const SectionDivider = () => {
  const theme: CustomTheme = useTheme();

  const dividerStyles = {
    bgcolor: theme.palette.grey[50],
    height: theme.spacing(),
    width: "100%",
  };

  return <Box sx={dividerStyles} />;
};

export default SectionDivider;
