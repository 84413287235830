import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

const ErrorBoundary = () => {
  useEffect(() => {
    // You can also log the error to an error reporting service
  }, []);

  return (
    <Box>
      <Typography variant="h1">Something went wrong.</Typography>
    </Box>
  );
};

export default ErrorBoundary;
