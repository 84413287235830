import menu from "locales/vi/menu";
import common from "locales/vi/common";
import contract from "locales/vi/contract";
import payment from "locales/vi/payment";

const vi = {
  menu,
  common,
  contract,
  payment
}

export default vi;
