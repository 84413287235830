const payment = {
  title: 'Thanh toán định kỳ',
  totalAmount: 'Tổng số tiền',
  paymentPeriod: 'Kỳ thanh toán',
  dueDate: 'Ngày đến hạn thanh toán',
  principalPayment: 'Gốc thanh toán của kỳ',
  daysLate: 'Số ngày trả chậm',
  previousPrincipal: 'Gốc còn thiếu của kỳ trước',
  latePaymentFees: 'Lãi phạt chậm trả',
  totalToBePaid: 'Thành tiền',
  toggleButton: {
    expand: 'Xem thêm',
    collapse: 'Thu gọn',
  },
  paymentGuide: {
    title: "Hướng dẫn thanh toán:",
    step1: { title: "Bước 1: ", description: "Mở app ngân hàng, chọn chuyển tiền 24/7" },
    step2: { title: "Bước 2: ", description: "Nhập NỘI DUNG CHÍNH XÁC để được ghi nhanh chóng (bấm \"Sao chép\" để sao chép)" },
    bank: "Ngân hàng",
    accountNumber: "Số tài khoản",
    amount: "Số tiền",
    narrative: "Nội dung chuyển khoản",
    copied: "Đã sao chép",
    copy: "Sao chép"
  }
}

export default payment;