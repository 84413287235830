import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { EMPTY_DATA } from "constants/constants";
import React, { ReactNode } from "react";
import { CustomTheme } from "@borrower-apps/theme";

interface DescriptionItemProps {
  label: string | ReactNode;
  value: string | number | ReactNode | undefined;
  icon?: ReactNode;
  justifyContent?: "start" | "space-between";
  color?: string;
  type?: "string" | "link" | "number";
}

const DescriptionItem: React.FC<DescriptionItemProps> = ({
  label,
  value,
  icon,
  color,
  justifyContent = "start",
  type = "string",
}) => {
  const theme: CustomTheme = useTheme();
  const textColor = color ?? theme.palette.text.primary;
  const align = justifyContent === "start" ? "left" : "right";
  const emptyValue = EMPTY_DATA;

  return (
    <Box color={textColor} sx={{ width: "100%" }}>
      <Stack spacing={2} direction="row" justifyContent={justifyContent}>
        <Box>
          <Stack spacing={0.5} direction="row" alignItems="center">
            {icon && (
              <Box
                lineHeight={1}
                fontSize={16}
                color={theme.palette.text.secondary}
              >
                {icon}
              </Box>
            )}
            <Typography variant="caption">{label}</Typography>
          </Stack>
        </Box>
        <Box flex={1} textAlign={align} overflow="hidden">
          {type === "string" && (
            <Typography variant="h3" color="inherit">
              {(value ?? emptyValue) || emptyValue}
            </Typography>
          )}
          {type === "number" && (
            <Typography variant="h3">
              {value === undefined || value === null ? emptyValue : ""}
            </Typography>
          )}
          {type === "link" && (
            <a
              href={value?.toString()}
              target="_blank"
              rel="noreferrer"
              style={{
                color: theme.colors.purple[900],
                textDecoration: "underline",
              }}
            >
              {value}
            </a>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default DescriptionItem;
